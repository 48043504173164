<script>
import { SuawMainContent, SuawSection } from "@suaw/suaw-component-library";
import ForgotPasswordForm from "../components/ForgotPasswordForm.vue";
import VerificationForm from "../components/VerificationForm.vue";
import ResetPasswordForm from "../components/ResetPasswordForm.vue";
import SuccessForm from "../components/SuccessForm.vue";
import * as UserApi from "../../api.js";
export default {
  name: "ResetPassword",
  components: { SuawMainContent, SuawSection, ResetPasswordForm, ForgotPasswordForm, VerificationForm, SuccessForm },
  data() {
    return {
      activeForm: "forgot-password"
    };
  },
  computed: {
    userEmail() {
      return this.$route.query.email || "your email";
    }
  },
  mounted() {
    const { token: refreshToken } = this.$route.params;
    const { step } = this.$route.query;
    if (refreshToken) {
      this.loginByToken(refreshToken);
    }
    if (step) {
      this.activeForm = step;
    }
  },
  methods: {
    async loginByToken(token) {
      const result = await UserApi.loginByToken(token);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
        return;
      }
      // begin rare case of doing more stuff inside the helper method before returning its result
      const { accessToken, user } = result.result.value || {};
      this.$auth.refreshAuthUser({ accessToken, user });
      this.activeForm = "reset-password";
      this.$router.push({ name: "ResetPasswordToken", params: { token } });
      // end rare case of doing more stuff inside the helper method before returning its result
      return result;
    },
    onPasswordResetRequested(email) {
      this.$router.push({
        name: "ResetPassword",
        query: {
          step: "verification",
          email: email
        }
      });
      this.activeForm = "verification";
    },
    async requestPasswordReset() {
      const result = await UserApi.requestPasswordReset(this.userEmail);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      } else {
        this.$root.$emit("universal-success-message", `A new code has successfully been sent to ${this.userEmail}.`);
      }
    },
    async onPasswordReset() {
      await this.$auth.logout({ redirectLocation: { name: "none" } });
      this.activeForm = "success";
    }
  }
};
</script>

<template>
  <SuawMainContent size="small">
    <SuawSection section-style="border">
      <ForgotPasswordForm v-if="activeForm === 'forgot-password'" @submit="onPasswordResetRequested" />
      <VerificationForm v-if="activeForm === 'verification'" :user-email="userEmail" @submit="loginByToken" @resend="requestPasswordReset" />
      <ResetPasswordForm v-if="activeForm === 'reset-password'" @submit="onPasswordReset" />
      <SuccessForm
        v-if="activeForm === 'success'"
        heading="Your password has been reset!"
        description="You have been logged out and must log in with your new password to complete the reset password process."
      />
    </SuawSection>
  </SuawMainContent>
</template>
