<script>
import { SuawForm, SuawHeading, SuawTextInput, SuawParagraph, SuawButton } from "@suaw/suaw-component-library";
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import * as UserApi from "../../api";

export default {
  name: "ResetPasswordForm",
  components: { SuawForm, SuawHeading, SuawTextInput, SuawParagraph, SuawButton },
  data() {
    return {
      resetPasswordForm: {
        password: "",
        confirmPassword: ""
      }
    };
  },
  validations: {
    resetPasswordForm: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(200)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  computed: {
    validateConfirmPasswordError() {
      const genericError = validateErrors(this.$v.resetPasswordForm.confirmPassword, "Password confirmation");
      if (genericError) return genericError;
      if (!this.$v.resetPasswordForm.confirmPassword.sameAsPassword) return "Passwords must match.";
      return "";
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    async resetPassword() {
      const result = await UserApi.resetPassword(this.$auth.user.id, this.resetPasswordForm.password);
      if (!result.success) {
        this.$root.$emit("universal-error-message", result.error);
      }
      return result;
    },
    async onSubmit() {
      this.$v.resetPasswordForm.$touch();
      if (this.$v.resetPasswordForm.$invalid) {
        this.$root.$emit("universal-error-message", "password reset form invalid.");
        return;
      }
      const resetPasswordResult = await this.resetPassword();
      if (!resetPasswordResult.success) {
        return;
      }
      await this.$auth.logout({ redirectLocation: { name: "none" } });
      this.$emit("submit");
    }
  }
};
</script>

<template>
  <SuawForm @submit="onSubmit">
    <template #form>
      <SuawHeading level="3" content="Reset Your Password" alignment="center" />
      <SuawTextInput
        id="password-pw-reset"
        v-model="resetPasswordForm.password"
        type="password"
        placeholder="Enter new password"
        is-required
        label="Password"
        message="Password must be at least 8 characters long."
        success-message="Password must be at least 8 characters long."
        :state="formFieldState($v, 'resetPasswordForm', 'password')"
        :error-message="validateErrors($v.resetPasswordForm.password, 'Password')"
        @blur="$v.resetPasswordForm.password.$touch()"
      />
      <SuawTextInput
        id="password-confirm-pw-reset"
        v-model="resetPasswordForm.confirmPassword"
        type="password"
        placeholder="Confirm new password"
        is-required
        label="Confirm Password"
        success-message="Passwords match!"
        :state="formFieldState($v, 'resetPasswordForm', 'confirmPassword')"
        :error-message="validateConfirmPasswordError"
        @blur="$v.resetPasswordForm.confirmPassword.$touch()"
      />
      <SuawParagraph size="small" text="You have been verified! You can now reset your password." alignment="left" />
      <SuawButton size="large" type="primary" button-text="Reset Password" />
    </template>
  </SuawForm>
</template>
