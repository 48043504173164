<script>
import { SuawForm, SuawHeading, SuawParagraph, SuawButton } from "@suaw/suaw-component-library";
export default {
  name: "SuccessForm",
  components: { SuawForm, SuawHeading, SuawParagraph, SuawButton },
  props: {
    heading: {
      type: String,
      default: "Success!"
    },
    description: {
      type: String,
      default: "You have been logged out and must log back in to continue."
    }
  },
  methods: {
    redirectToSignIn() {
      this.$router.push({ name: "SignIn" });
    }
  }
};
</script>

<template>
  <SuawForm @submit="redirectToSignIn">
    <template #form>
      <SuawHeading level="3" :content="heading" alignment="center" />
      <SuawParagraph size="small" :text="description" alignment="left" />
      <SuawButton size="large" type="primary" button-text="Log In" />
    </template>
  </SuawForm>
</template>
