<script>
import { SuawForm, SuawHeading, SuawInputGroup, SuawTextInput, SuawParagraph, SuawButton } from "@suaw/suaw-component-library";
import { validateErrors, formFieldState } from "../../../../utils/api/validationErrors";
import { required } from "vuelidate/lib/validators";

export default {
  name: "VerificationForm",
  components: { SuawForm, SuawHeading, SuawInputGroup, SuawTextInput, SuawParagraph, SuawButton },
  props: {
    heading: {
      type: String,
      default: "Verify Your Email"
    },
    resendCooldownTime: {
      type: Number,
      default: 30
    },
    userEmail: {
      type: String,
      default: "your email"
    }
  },
  data() {
    return {
      verificationForm: {
        token: ""
      },
      isResendDisabled: false,
      resendTimer: 0 // Timer for countdown
    };
  },
  validations: {
    verificationForm: {
      token: { required }
    }
  },
  computed: {
    description() {
      return `Verify your identity by entering the code we've sent to <b>${this.userEmail}</b>. Or, enter the code from your verification email above.`;
    }
  },
  methods: {
    validateErrors,
    formFieldState,
    uppercaseToken() {
      this.verificationForm.token = this.verificationForm.token.toUpperCase();
    },
    onSubmit() {
      this.$v.verificationForm.$touch();
      if (this.$v.verificationForm.$invalid) {
        this.$root.$emit("universal-error-message", "verification form invalid");
      } else {
        this.$emit("submit", this.verificationForm.token);
      }
    },
    async onResendCode() {
      if (this.isResendDisabled) return;
      this.isResendDisabled = true; // Disable the button
      this.resendTimer = this.resendCooldownTime; // Start the countdown timer
      this.$emit("resend");
      // Start the countdown
      const countdownInterval = setInterval(() => {
        if (this.resendTimer > 0) {
          this.resendTimer--;
        } else {
          clearInterval(countdownInterval);
          this.isResendDisabled = false;
        }
      }, 1000);
    }
  }
};
</script>

<template>
  <SuawForm>
    <template #form>
      <SuawHeading level="3" :content="heading" alignment="center" />
      <SuawTextInput
        id="token-verification"
        v-model="verificationForm.token"
        type="text"
        placeholder="Enter code"
        is-required
        label="Verification code"
        :state="formFieldState($v, 'verificationForm', 'token')"
        :error-message="validateErrors($v.verificationForm.token, 'Verification code')"
        @input="uppercaseToken"
        @blur="$v.verificationForm.token.$touch()"
      />
      <SuawParagraph size="small" :text="description" alignment="left" />
      <SuawInputGroup direction="column" group-gap="base">
        <SuawButton size="large" type="primary" button-text="Verify Code" @click="onSubmit" />
        <SuawButton
          size="large"
          type="ghost"
          :button-text="isResendDisabled ? `Resend Code (${resendTimer}s)` : 'Resend Code'"
          :disabled="isResendDisabled"
          @click="onResendCode"
        />
      </SuawInputGroup>
    </template>
  </SuawForm>
</template>
